import Cookies from "js-cookie";

export default () => {
  const button = document.querySelector(".js-cookie-confirm");
  if (!button) return;
  button.addEventListener("click", () => {
    const wrapper = button.closest(".cookies-banner");
    wrapper && wrapper.remove();
    Cookies.set("cookies_confirmed", "1", { expires: 1000 });
  });
};
