let isMobile = false;

export default () => {
  const quest = document.querySelector(".quest");
  const pictBlock = document.querySelector(".quest__picture");
  const infoBlock = document.querySelector(".quest__info");
  const supportBlock = document.querySelector(".quest__support");
  const buttonsBlock = document.querySelector(".quest__buttons");
  const shareBlock = document.querySelector(".quest__share");
  const reviewsBlock = document.querySelector(".quest__reviews-block");
  const companyBlock = document.querySelector(".quest__company");
  const title = document.querySelector(".quest__title");
  const charsBlock = document.querySelector(".quest__chars");
  if (!quest) return;
  if (window.innerWidth < 641) {
    if (isMobile) return;
    infoBlock.prepend(pictBlock);
    title.after(charsBlock);
    charsBlock.after(buttonsBlock);
    buttonsBlock.after(shareBlock);
    reviewsBlock.before(companyBlock);
    isMobile = true;
  } else {
    if (!isMobile) return;
    supportBlock.prepend(pictBlock);
    pictBlock.after(buttonsBlock);
    buttonsBlock.after(shareBlock);
    shareBlock.after(companyBlock);
    reviewsBlock.before(charsBlock);
    isMobile = false;
  }
};
