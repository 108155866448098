export default () => {
  const hideBlock = document.querySelector(".quest__text-block");
  if (!hideBlock) return;
  const showBtn = document.createElement("button");
  showBtn.classList.add("show-more", "js-toggle-height");
  showBtn.setAttribute("type", "button");

  let isHideBlock = false;
  const height = hideBlock.offsetHeight;
  const defaultHeight = window.innerWidth < 641 ? "7.125rem" : "8.125rem";
  if (height > 190) {
    hideBlock.style.height = defaultHeight;
    isHideBlock = true;
    showBtn.textContent = hideBlock.getAttribute("data-open");
    hideBlock.parentElement.append(showBtn);
    showBtn.addEventListener("click", e => {
      if (isHideBlock) {
        hideBlock.style.height = `${height}px`;
        isHideBlock = false;
        showBtn.textContent = hideBlock.getAttribute("data-close");
        showBtn.classList.add("show-more_active");
      } else {
        hideBlock.style.height = defaultHeight;
        isHideBlock = true;
        showBtn.textContent = hideBlock.getAttribute("data-open");
        showBtn.classList.remove("show-more_active");
      }
    });
  }
};
