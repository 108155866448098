const setTabActive = tab => {
  if (tab.classList.contains("-active-")) return;
  tab.classList.add("-active-");
  for (let i = 0; i < tab.parentElement.children.length; i++) {
    const tabItem = tab.parentElement.children[i];
    if (tabItem !== tab) tabItem.classList.remove("-active-");
  }
  const targetTab = tab.getAttribute("data-tab-target");
  const targetContentBlock = document.querySelector(
    `[data-tab="${targetTab}"]`
  );
  const tabGroup = targetContentBlock.getAttribute("data-tab-group");
  const tabGroupBlocks = document.querySelectorAll(
    `[data-tab-group="${tabGroup}"]`
  );
  [].forEach.call(tabGroupBlocks, contentBlock => {
    if (contentBlock !== targetContentBlock)
      contentBlock.classList.remove("-active-");
  });
  targetContentBlock.classList.add("-active-");
  if (targetTab === "map") {
    const lazymap = document.getElementById("lazymap");
    if (!lazymap) return;
    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.async = true;
    scriptTag.src = lazymap.getAttribute("data-src");
    lazymap.parentNode.removeChild(lazymap);
    targetContentBlock.appendChild(scriptTag);
  }
};

export default () => {
  const Tabs = document.querySelectorAll("[data-tab-target]");
  if (!Tabs) return;
  [].forEach.call(Tabs, tab => {
    tab.addEventListener("click", e => {
      e.preventDefault();
      setTabActive(tab);
    });
  });
};
