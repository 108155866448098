export const slideUp = (element, duration = 300) => {
  const target = element;
  if (target.offsetHeight === 0) return;
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = `${duration}ms`;
  target.style.height = `${target.offsetHeight}px`;
  window.setTimeout(() => {
    target.style.height = "0px";
  }, 10);
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.style.overflow = "hidden";
  window.setTimeout(() => {
    target.style.display = "none";
    target.style.removeProperty("height");
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};

export const slideDown = (el, duration = 300) => {
  const target = el;
  if (target.offsetHeight > 0) return;
  target.style.removeProperty("display");
  let { display } = window.getComputedStyle(target);
  const {
    marginTop,
    marginBottom,
    paddingTop,
    paddingBottom
  } = window.getComputedStyle(target);
  if (display === "none") {
    display = "block";
  }
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.display = display;
  const height = target.offsetHeight;
  target.style.height = 0;
  target.style.overflow = "hidden";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = `${duration}ms`;
  window.setTimeout(() => {
    target.style.height = `${height}px`;
    target.style.marginTop = marginTop;
    target.style.marginBottom = marginBottom;
    target.style.paddingTop = paddingTop;
    target.style.paddingBottom = paddingBottom;
  }, 10);
  window.setTimeout(() => {
    target.style.removeProperty("height");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};

export function slideToggle(target, duration = 300) {
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, duration);
  }
  return slideUp(target, duration);
}
