document.addEventListener("click", e => {
  const btn = e.target;
  if (!btn.classList.contains("js-reviews-more")) return;
  const offset = +btn.getAttribute("data-offset");
  const wrapper = document.querySelector(".js-review-list");
  fetch(`${window.location.href}?offset=${offset}&nolayout=true`)
    .then(resp => resp.text())
    .then(html => {
      wrapper.innerHTML += html;
      btn.setAttribute("data-offset", offset + 1);
    });
});
