document.addEventListener(
  "click",
  ({ target }) => {
    if (!target) return;
    if (!target.classList.contains("set-lang")) return;
    const { locale } = target.dataset;
    document.cookie = `locale=${locale}; path=/`;
    document.location.reload(true);
  },
  false
);
