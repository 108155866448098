export default () => {
  /* Показываем/прячем пароль */
  const pswIcons = document.querySelectorAll(".js-icon-psw");
  [].forEach.call(pswIcons, pswIcon => {
    pswIcon.addEventListener("click", () => {
      const parentBlock = pswIcon.parentElement;
      const siblingInput = parentBlock.querySelector(".js-input-psw");
      if (pswIcon.parentElement.classList.contains("-open-")) {
        parentBlock.classList.remove("-open-");
        siblingInput.setAttribute("type", "password");
      } else {
        parentBlock.classList.add("-open-");
        siblingInput.setAttribute("type", "text");
      }
    });
  });
};
