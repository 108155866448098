import "./closest_polyfill";
import "./fixed_header";
import "./dropdown";
import "./language_switcher";
import "./anchor_scroll";
import forms from "./forms";
import LazyLoad from "vanilla-lazyload";
import amusementDescription from "./amusement_description";
import select from "./select";
import counters from "./counters";
import tabs from "./tabs";
import "fslightbox";
import mobileMenu from "./mobile_menu";
import modals from "./modals";
import citySearch from "./city_search";
import chooseRating from "./choose_rating";
import searchFilter from "./search_filter";
import purchaseForm from "./purchase_form";
import profilePage from "./profile_page";
import accordion from "./accordion";
import amusementInfo from "./amusement_info";
import amusementListSort from "./amusement_list_sort";
import amusementListFilter from "./amusement_list_filter";
import cookiesConfirmation from "./cookies_confirmation";
import "./reservation_photos";
import "./reviews";

document.addEventListener(
  // "turbolinks:load", // Enable for turbolinks
  "DOMContentLoaded", // Disable for turbolinks
  () => {
    forms();
    new LazyLoad();
    amusementDescription();
    select();
    counters();
    if (
      document.querySelector(".events-slider__box") ||
      document.querySelector(".theme-swiper") ||
      document.querySelector(".about-feature__content") ||
      document.querySelector(".tabs-button__container")
    ) {
      import("./swipers").then(mod => mod.default());
    }
    tabs();
    window.refreshFsLightbox && window.refreshFsLightbox();
    mobileMenu();
    modals();
    citySearch();
    chooseRating();
    searchFilter();
    purchaseForm();
    profilePage();
    accordion();
    amusementInfo();
    amusementListSort();
    amusementListFilter();
    cookiesConfirmation();
  },
  false
);

window.addEventListener("resize", () => {
  amusementInfo();
  searchFilter();
});
