function modalHandler(handler, block) {
  const body = document.querySelector("body");
  const container = document.querySelectorAll(".modal__container");
  const targetBlock = document.querySelector(`[data-modal="${block}"]`);
  const modal = document.querySelector("#modal");
  const handlers = {
    open: () => {
      if (modal.style.display === "block") {
        [].forEach.call(container, item => (item.style.display = "none"));
      }
      body.style.overflow = "hidden";
      modal.style.display = "block";
      if (targetBlock) {
        targetBlock.classList.add("modal__container_close");
        targetBlock.style.display = "block";
        const firstTextInput = targetBlock.querySelector('input[type="text"]');
        if (firstTextInput) firstTextInput.focus();
        setTimeout(() => {
          targetBlock.classList.remove("modal__container_close");
        }, 1);
      }
    },
    close: () => {
      body.style.overflow = "auto";
      modal.style.display = "none";
      [].forEach.call(container, item => (item.style.display = "none"));
    }
  };

  handlers[handler]();
}

export default () => {
  const buttons = document.querySelectorAll("[data-modal-target]");
  const modal = document.querySelector(".modal");

  [].forEach.call(buttons, button => {
    button.addEventListener("click", () => {
      const target = button.getAttribute("data-modal-target");
      modalHandler("open", target);

      if (target !== "buycard") return;
      const sumInput = document.getElementById("gift_card_sum");
      const sum = button.getAttribute("data-sum");
      const modalTotal = document.querySelector(".modal-total");
      if (sum) {
        sumInput.value = sum;
        sumInput.type = "hidden";
        modalTotal.removeAttribute("hidden");
        modalTotal.querySelector("#gift-card-total-sum").innerHTML = sum;
      } else {
        sumInput.type = "number";
        modalTotal.setAttribute("hidden", "true");
      }
    });
  });

  if (!modal) return;

  modal.addEventListener("click", e => {
    if (
      e.target.classList.contains("js-modal-close") ||
      e.target.closest(".js-modal-close") ||
      e.target === modal
    ) {
      modalHandler("close");
    }
  });
};
