function sortFunc(firstAsString, secondAsString, asc) {
  const first = +firstAsString || firstAsString;
  const second = +secondAsString || secondAsString;
  if (asc) {
    return first > second ? 1 : first < second ? -1 : 0;
  } else {
    return first < second ? 1 : first > second ? -1 : 0;
  }
}

export default () => {
  const selectorList = document.querySelectorAll(".js-sort");
  [].forEach.call(selectorList, selector => {
    selector.addEventListener("click", () => {
      const list = document.querySelector(".js-sortable-list");
      const elements = list.querySelectorAll(".quest-grid__item");
      const previousSortedKey = list.getAttribute("data-sort-key");
      const newSortKey = selector.getAttribute("data-sort-target");
      let isAsc = true;
      if (previousSortedKey === newSortKey) {
        const previousIsAsc = list.getAttribute("data-sort-asc");
        isAsc = !(previousIsAsc === "true");
      }
      const sorted = [...elements].sort((current, next) => {
        const currentValAsStr = current.getAttribute(`data-sort-${newSortKey}`);
        const nextValAsStr = next.getAttribute(`data-sort-${newSortKey}`);
        return sortFunc(currentValAsStr, nextValAsStr, isAsc);
      });
      [].forEach.call(elements, element => list.removeChild(element));
      sorted.forEach(element => list.appendChild(element));
      list.setAttribute("data-sort-key", newSortKey);
      list.setAttribute("data-sort-asc", isAsc);
    });
  });
};
