function toggleDisabled(button, isTrue) {
  if (isTrue) return button.setAttribute("disabled", "disabled");
  button.removeAttribute("disabled");
}

export default () => {
  const form = document.querySelector(".js-filter-form");
  if (!form) return;
  const buttons = form.querySelectorAll(".js-filter-form-counter-button");
  if (!buttons) return;
  [].forEach.call(buttons, button => {
    const counterBlock = button.closest(".form-counter");
    const input = counterBlock.querySelector("input");
    const minVal = +input.getAttribute("data-min");
    const maxVal = +input.getAttribute("data-max");
    const decButton = counterBlock.querySelector(".js-filter-form-minus");
    const incButton = counterBlock.querySelector(".js-filter-form-plus");
    const stepVal = +button.getAttribute("data-step");
    if (+input.value >= maxVal) toggleDisabled(incButton, true);
    if (+input.value <= minVal) toggleDisabled(decButton, true);
    button.addEventListener("click", () => {
      const currentVal = +input.value;
      const newVal = currentVal + stepVal;
      if (newVal < minVal || newVal > maxVal) return;
      input.value = newVal;
      if (newVal > minVal) toggleDisabled(decButton, false);
      if (newVal < maxVal) toggleDisabled(incButton, false);
      if (newVal >= maxVal) toggleDisabled(incButton, true);
      if (newVal <= minVal) toggleDisabled(decButton, true);
    });
  });
};
