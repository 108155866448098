import { slideUp, slideDown } from "./animation";

function selectToggle(dropdown, show = true) {
  if (!dropdown) return;

  const btn = dropdown.previousElementSibling;
  if (btn.classList.contains("js-select-toggle")) {
    show
      ? btn.classList.add("-select-active-")
      : btn.classList.remove("-select-active-");
  }

  if (show) {
    dropdown.classList.add("-select-active-");
    slideDown(dropdown, 300);
  } else {
    dropdown.classList.remove("-select-active-");
    slideUp(dropdown);
  }
}

document.addEventListener(
  "click",
  e => {
    const btn = e.target.closest(".js-select-toggle");
    if (!btn) return;
    const dropdown = btn.nextElementSibling;
    if (!dropdown || !dropdown.classList.contains("js-select")) return;
    e.preventDefault();
    e.stopPropagation();
    const dropdowns = document.querySelectorAll(".js-select");

    // if (dropdowns) {
    //   for (let i = 0; i < dropdowns.length; i++) {
    //     const dropItem = dropdowns[i];

    //     if (dropItem !== dropdown) {
    //       if (dropItem.classList.contains("-select-active-")) {
    //         selectToggle(dropItem, false);
    //       }
    //     }
    //   }
    // }

    selectToggle(dropdown, !btn.classList.contains("-select-active-"));
  },
  true
);

// /* Закрываем селект при клике вне его области */
// document.addEventListener("click", e => {
//   const dropdowns = document.querySelectorAll(".js-select");

//   if (dropdowns) {
//     for (let i = 0; i < dropdowns.length; i++) {
//       const dropdown = dropdowns[i];

//       if (dropdown !== e.target && !e.target.closest(".js-select")) {
//         if (dropdown.classList.contains("-select-active-")) {
//           selectToggle(dropdown, false);
//         }
//       }
//     }
//   }
// });

export default () => {
  const selectItems = document.querySelectorAll(".select__item");
  if (!selectItems) return;
  [].forEach.call(selectItems, selectItem => {
    selectItem.addEventListener("click", () => {
      const itemText = selectItem.textContent;
      const select = selectItem.closest(".select");
      const selectText = select.querySelector(".js-select-text");
      const selectField = select.querySelector(".select__dropdown");
      selectText.textContent = itemText;
      selectToggle(selectField, false);
    });
  });
};
