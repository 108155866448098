import { slideUp, slideDown } from "./animation";

function dropdownToggle(dropdown, show = true) {
  if (!dropdown) return;

  const btn = dropdown.previousElementSibling;
  if (btn.classList.contains("js-dropdown-toggle")) {
    show ? btn.classList.add("-active-") : btn.classList.remove("-active-");
  }

  if (show) {
    dropdown.classList.add("-active-");
    slideDown(dropdown);
  } else {
    dropdown.classList.remove("-active-");
    slideUp(dropdown);
  }
}

document.addEventListener(
  "click",
  e => {
    const item = e.target.closest(".js-dropdown-toggle");
    if (!item) return;
    const dropdown = item.nextElementSibling;
    if (!dropdown.classList.contains("js-dropdown")) return;
    e.preventDefault();
    e.stopPropagation();

    // const dropdowns = document.querySelectorAll(".js-dropdown");

    // if (dropdowns) {
    //   for (let i = 0; i < dropdowns.length; i++) {
    //     const dropItem = dropdowns[i];
    //     if (dropItem !== dropdown) {
    //       if (dropItem.classList.contains("-active-")) {
    //         dropdownToggle(dropItem, false);
    //       }
    //     }
    //   }
    // }

    dropdownToggle(dropdown, !item.classList.contains("-active-"));
  },
  true
);

/* Закрываем дропдаун при клике вне его области */
// document.addEventListener("click", e => {
//   const dropdowns = document.querySelectorAll(".js-dropdown");
//   if (dropdowns) {
//     for (let i = 0; i < dropdowns.length; i++) {
//       const dropdown = dropdowns[i];
//       if (dropdown !== e.target && !e.target.closest(".js-dropdown")) {
//         if (dropdown.classList.contains("-active-")) {
//           dropdownToggle(dropdown, false);
//         }
//       }
//     }
//   }
// });
