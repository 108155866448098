export default () => {
  const ELEMENTS = document.querySelectorAll(".main-counters__number");
  const TIMER = 3000;
  const DELAY = 100;
  const ATTRIB_REGEXP = /(\d)(?=(\d{3})+(?!\d))/g;

  const counters = [].map.call(ELEMENTS, elem => {
    const limit = +elem.getAttribute("data-counter").replace(/ /g, "");
    return { elem, limit, isFull: false };
  });

  function inc(timePassed) {
    counters.forEach(counter => {
      const limit = counter.limit;
      let newVal = (limit * timePassed) / TIMER;
      if (newVal >= limit) {
        newVal = limit;
        counter.isFull = true;
      }
      const outNumb = newVal.toFixed(0).replace(ATTRIB_REGEXP, "$1 ");
      counter.elem.setAttribute("data-counter-now", outNumb);
    });
  }

  function countIncrement() {
    const start = Date.now();
    const interval = setInterval(() => {
      const timePassed = Date.now() - start;
      if (timePassed > TIMER && counters.every(item => item.isFull)) {
        clearInterval(interval);
        return;
      }
      inc(timePassed);
    }, DELAY);
  }

  countIncrement();
};
