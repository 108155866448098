import { slideDown, slideUp } from "./animation";

export default () => {
  const field = document.querySelector(".js-cabinet-field");

  const button = document.querySelector(".js-toggle-cabinet-field");
  if (button) {
    button.addEventListener("click", () => {
      field.offsetParent ? slideUp(field) : slideDown(field);
    });
  }

  const closeButton = document.querySelector(".js-close-cabinet-field");
  if (closeButton) {
    closeButton.addEventListener("click", () => slideUp(field));
  }

  const copyButton = document.querySelector(".js-copy-input-val");
  if (!copyButton) return;
  const input = copyButton.parentElement.querySelector(".form__input");
  if (!copyButton || !input) return;
  copyButton.addEventListener("click", () => {
    input.select();
    document.execCommand("copy");
  });
};
