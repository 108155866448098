import "fslightbox";

function createImgEl(src) {
  const img = document.createElement("img");
  img.src = src;
  img.classList.add("quest-winners__img");
  return img;
}

function createLinkWrapper(img) {
  const link = document.createElement("a");
  link.href = img.src;
  link.setAttribute("data-fslightbox", "winners");
  link.setAttribute("data-turbolinks", "false");
  link.classList.add("quest-winners__link");
  link.appendChild(img);
  return link;
}

function createListElement(link) {
  const li = document.createElement("li");
  li.classList.add("quest-winners__item", "swiper-slide");
  li.appendChild(link);
  return li;
}

function reloadreservationPhotos(month, year) {
  const id = document
    .querySelector(".quest-winners__selects")
    .getAttribute("data-id");
  fetch(`/amusements/${id}/reservation_photos.json?month=${month}&year=${year}`)
    .then(resp => resp.json())
    .then(urls => {
      const elements = urls.map(url => {
        const img = createImgEl(url);
        const link = createLinkWrapper(img);
        const li = createListElement(link);
        return li;
      });
      const list = document.querySelector("ul.quest-winners__list");
      list.textContent = "";
      elements.forEach(el => list.appendChild(el));
      window.refreshFsLightbox && window.refreshFsLightbox();
    });
}

document.addEventListener("click", e => {
  const selection = e.target;
  if (!selection.classList.contains("js-reservation-photos")) return;
  if (selection.classList.contains("js-reservation-photos-year")) {
    const selectedMonth = document.querySelector(
      ".js-reservation-photos-selected-month"
    ).innerHTML;
    const selectedYear = selection.getAttribute("data-year");
    reloadreservationPhotos(selectedMonth, selectedYear);
  } else if (selection.classList.contains("js-reservation-photos-month")) {
    const selectedMonth = selection.getAttribute("data-month");
    const selectedYear = document.querySelector(
      ".js-reservation-photos-selected-year"
    ).innerHTML;
    reloadreservationPhotos(selectedMonth, selectedYear);
  }
});
