import { slideUp, slideToggle } from "./animation";

export default () => {
  const btns = document.querySelectorAll(".accordion__block");
  const fields = document.querySelectorAll(".accordion__field");

  if (btns && fields) {
    [].forEach.call(btns, btn => {
      btn.addEventListener("click", () => {
        slideToggle(btn.nextElementSibling);
        btn.classList.toggle("-active-");

        [].forEach.call(fields, field => {
          if (field !== btn.nextElementSibling) slideUp(field);
        });

        [].forEach.call(btns, item => {
          if (item !== btn) item.classList.remove("-active-");
        });
      });
    });
  }
};
