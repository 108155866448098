export default () => {
  const ratingList = document.querySelectorAll(".choose-rating__item");
  if (!ratingList) return;
  [].forEach.call(ratingList, rating => {
    rating.addEventListener("click", () => {
      const allItems = rating.parentElement.querySelectorAll(
        ".choose-rating__item"
      );
      [].forEach.call(allItems, item => item.classList.remove("-active-"));
      rating.classList.add("-active-");
    });
  });
};
