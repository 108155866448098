export default () => {
  const menuToggle = document.querySelector(".js-menu-toggle");
  const menuField = document.querySelector(".js-menu-field");
  const body = document.querySelector("body");
  let isShowMenu = false;

  function toggleMenu(isShow) {
    isShowMenu = !isShow;
    if (isShow) {
      menuField.classList.remove("-open-");
      menuToggle.classList.remove("-active-");
      body.style.overflow = "auto";
    } else {
      menuField.classList.add("-open-");
      menuToggle.classList.add("-active-");
      body.style.overflow = "hidden";
    }
  }

  if (menuToggle)
    menuToggle.addEventListener("click", () => toggleMenu(isShowMenu));
};
