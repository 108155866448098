function debounce(func, wait) {
  let timeout;
  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function fetchList(e) {
  const cityList = document.querySelector("ul.modal-city__list");
  if (!cityList) return;
  const value = e.target.value.toLowerCase();
  fetch(`/cities.json?term=${value}`)
    .then(resp => resp.json())
    .then(cities => {
      const html = cities
        .map(
          ({ name, url }) =>
            `<li class="modal-city__item"><a class="modal-city__link" href=${url}>${name}</a></li>`
        )
        .join("");
      cityList.innerHTML = html;
    });
}

export default () => {
  const citySearchInput = document.getElementById("city-search-input");
  if (!citySearchInput) return;
  citySearchInput.addEventListener("input", debounce(fetchList, 500));
  // const cityListElements = document.querySelectorAll(
  //   ".modal-city__link.filterable"
  // );
  // citySearchInput.addEventListener("input", e => {
  //   const value = e.target.value.toLowerCase();
  //   const matched = [];
  //   [].forEach.call(cityListElements, elem => {
  //     if (!elem) return;
  //     elem.style.display = "none";
  //     if (elem.innerHTML.toLowerCase().indexOf(value) === 0) matched.push(elem);
  //   });
  //   matched
  //     .slice(0, 9)
  //     .forEach(matchedElem => (matchedElem.style.display = "inline-block"));
  // });
};
